import Node from 'ln/node/Node';
import List from 'ln/list/List';
import LinkedList from 'ln/linkedlist/LinkedList';
import { scanner } from 'ln/view/Scanner';
import { mapper } from '../LernBuch';
import { ioc as chapterIoC } from '../elements/Chapter';
import LernBuch from '../LernBuch';
import Element from '../elements/Element';
import Gallery from '../elements/Gallery';
import ChapterNavigation from '../navigation/chapter/Navigation';
import OutlineNavigation from '../navigation/outline/OutlineNavigation';
import PagingNavigation from '../navigation/paging/PagingNavigation';
import SearchInput from '../search/SearchInput';
import SearchOutput from '../search/SearchOutput';
import ShareDialog from '../share/ShareDialog';
import BookModel from '../models/BookModel';
import ChapterModel from '../models/ChapterModel';
import ElementModel from '../models/ElementModel';
import GalleryImageModel from '../models/GalleryImageModel';
import GalleryModel from '../models/GalleryModel';
import Section from '../elements/Section';
import ToC from '../elements/ToC';

export function defaultsetup(){

    models();
    lernbuch();
    chapter();

};

export function models(){

    // Book
    mapper.toModel.add( 'Book', function ( json ) {
        var book = new BookModel( json );

        book.chapters.forEach( function( chapter:ChapterModel ){
            chapter.set( 'parent', book );
        }, this );

        LinkedList.fromArray( book.chapters );
        
        return book
	});
    mapper.toModel.alias( 'App\\Book', 'Book' );

    // Chapter
    mapper.toModel.add( 'Chapter', function( json:{ elements:any} ){
        var chapter = new ChapterModel( json );
    
        chapter.elements = new List<ChapterModel>( json.elements );
        chapter.elements.all().forEach( function( element ){
            element.set( 'chapter', chapter );
        }, this );
    
        return chapter;
    });

    mapper.toModel.alias( 'App\\Chapter', 'Chapter' );

    // Gallery
    mapper.toModel.add( 'Gallery', function( json ) {
        return new GalleryModel( json );
    });
    mapper.toModel.alias( 'App\\Gallery', 'Gallery' );

    // GalleryImage
    mapper.toModel.add( 'GalleryImage', function( json ) {
        return new GalleryImageModel( json );
    });
    mapper.toModel.alias( 'App\\GalleryImage', 'GalleryImage' );

    // Image
    mapper.toModel.add( 'Image', function( json ) {
        return new ElementModel( json );
    });
    mapper.toModel.alias( 'App\\Image', 'Image' );

    // default
    mapper.toModel.add( 'default', function( json ) {
        var element = new ElementModel( json );
        return element;
    });

}

export function lernbuch(){

    // Navigation
    scanner.ioc.add( 'navigation', function( node:Node, lernbuch:LernBuch ) {
        var navi = new ChapterNavigation( lernbuch ).render( node ) as ChapterNavigation;
        navi.renderChapters( lernbuch.book.chapters, { modelNames: [ 'Title', 'Image', 'Gallery' ], level: 3 } );
        return navi;
    });
    
    // Outline Navigation
    scanner.ioc.add( 'outline-navigation', function( node:Node, lernbuch:LernBuch ) {
		return new OutlineNavigation( lernbuch ).render( node );
    });
    
    // Next-Previous-Navigation
    scanner.ioc.add( 'paging-navigation', function( node:Node, lernbuch:LernBuch ) {
		return new PagingNavigation( lernbuch ).render( node );
    });
    
    // Search
    scanner.ioc.add( 'search-input', function( node:Node, lernbuch:LernBuch ) {
        return new SearchInput( lernbuch.book ).render( node );
    });

    // Search
    scanner.ioc.add( 'search-output', function( node:Node, lernbuch:LernBuch ) {
        var output = new SearchOutput( lernbuch ).render( node ) as SearchOutput;
        output.listenTo( lernbuch.searchInput );
        return output;
    });

    // Share Dialog
    scanner.ioc.add( 'share-dialog', function( node:Node, lernbuch:LernBuch ){
        return new ShareDialog( lernbuch ).render( node );
    })

}

export function chapter(){
    
    // default
    chapterIoC.add( 'default', function ( model:ElementModel ) {
        return new Element( model ).render() as Element;
    });
    
    // Gallery
    chapterIoC.add( 'Gallery', function( model:ElementModel ) {
        return new Gallery( <GalleryModel> model ).render() as Element;
    });
    chapterIoC.alias( 'App\\Gallery', 'Gallery' );
    
    // Section
    chapterIoC.add( 'Section', function( model:ElementModel ) {
        return new Section( model ).render() as Element;
    });
    chapterIoC.alias( 'App\\Section', 'Section' );

    // ToC
    chapterIoC.add( 'ToC', function( model:ElementModel ) {
        return new ToC( model ).render() as Element;
    });
    chapterIoC.alias( 'App\\ToC', 'ToC' );
}
