import Signal from 'ln/signal/Signal';
import Node from 'ln/node/Node';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import template from 'ln/template/TemplateManager';
import setup from 'ln/setup/setup';
import ChapterModel from '../../models/ChapterModel';
import LernBuch from '../../LernBuch';
import ChapterElement from './ChapterElement';
import TreeNode from './tree/TreeNode';
import TreeView from './tree/TreeView';
import Navigation from './Navigation';
import Element from '../../elements/Element';

export interface ChapterCallback{
	( chapter:Chapter )
}

/**
 * Renders a list chapters used for navigation between chapters
 */
class Chapter extends TreeView{

	public onSelected:Signal<ChapterCallback>;
	private currentPath:TreeNode[];

	constructor( public treeNode:TreeNode ) {
		
		super( treeNode );
		this.defaultTemplate = 'lb.navigation-chapter';
		this.onSelected = new Signal<ChapterCallback>();
		this.currentPath = [];
	}
	
	/**
	 * Render the template for the chapters
	 */
	public init() {
		
		this.viewList.container = this.node.js( 'elements' );
		this.viewList.defaultRender( function( node:TreeNode ) {
			return new ChapterElement( node ).render();
		});
		
		if( this.treeNode.children.length > 0 ) {
			this.renderChildren( this.treeNode.children );
			this.node.js( 'toggle' ).click.add( this.onToggle, this );
		} else {
			this.node.js( 'toggle' ).style.visibility = 'hidden';
		}

		this.node.js( 'title' ).click.add( this.onChapter, this );
		
	}

	/**
	 * Close the elements of this navigation chapter
	 */
	public closeElements(){
		this.node.removeClass( '-open' );
	}

	/**
	 * Close the elements of this navigation chapter
	 */
	public openElements(){
		this.node.addClass( '-open' );
	}

	
	private onChapter() {
		this.onSelected.dispatch( this );
		window.scrollTo( 0, 0 );
	}

	/**
	 * Toggle elements of this chapter
	 * @param n 
	 * @param e 
	 */
	private onToggle( n:Node, e:MouseEvent ) {
		if( !this.node.hasClass( '-open' ) ) ( this.root as Navigation ).hideAllNavigationChapters();
		this.node.toggleClass( '-open' );
	}

	/**
	 * Check if path to one of the visible elements exists
	 * @param visibleElements 
	 */
	public checkPathToElement( visibleElements:Element[] ):TreeNode[]{

		var path = [];
		visibleElements.some( visibleElement => {
			let p = this.treeNode.getPath( visibleElement.model );
			if( p.length > 0){
				path = p;
				return true
			}
			return false;
		});

		return ( path.length == 0 ) ? undefined : path;
	}
}

export default Chapter;
