import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Window from 'ln/node/Window';
import Template from 'ln/template/TemplateManager';
import LernBuch from '../LernBuch';
import Element from '../elements/Element';

export interface ShareConfig{
	facebook:string,
	twitter:string,
	mail:string
}

/**
 * Dialogbox for sharing content of the lb
 */
class ShareDialog extends View {

	private config:ShareConfig = {
		twitter: "https://twitter.com/home?status=",
		facebook: "https://www.facebook.com/sharer/sharer.php?u=",
		mail: "mailto:?body="
	};
	
	
	constructor( public lernbuch:LernBuch, template:string = 'lb.share-dialog' ) {
		super();

		this.lernbuch.scrollMonitor.change.add( this.updateLinks, this );
		this.defaultTemplate = template;
	}

	/**
	 * Set the config for the share dialog
	 * @param config 
	 */
	public setConfig( config:ShareConfig ){
		this.config = config;
	}
	
	/**
	 * Renders the template for the sharedialog
	 */
	public init() {
		this.node.js( 'toggle' ).click.add( this.toggleShare, this );
		this.setLinkNodes();
	}

	private setLinkNodes(){
		for( var key in this.config ){
			this.node.js( 'links' ).append( Node.fromHTML( Template.render( 'lb.share-link', { key: key } ) ) );
		}
	}
	
	/**
	 * Toggles the visibility of the share dialog
	 */
	private toggleShare():void {
		this.node.toggleClass( '-open' );
	}
	
	/**
	 * Update the share links
	 */
	private updateLinks( visibleElements:Element[]):void {
		var input = this.node.js( 'input' );
		if( visibleElements.length == 0 ){
			input.value = window.location.toString();
			this.setShareLinks( window.location.toString() );
		} else {
			input.value = this.setHash( visibleElements[0] );
			this.setShareLinks( this.setHash( visibleElements[0] ) );
		}
	}
	
	/**
	 * Set the share links
	 */
	private setShareLinks( url: string ) {
		
		for( var key in this.config ){
			var link = this.node.js( key );
			if( key == 'mail' ){
				link.setAttribute( 'href', this.config[key] + this.setMailBody( url ) );
			} else {
				link.setAttribute( 'href', this.config[key] + encodeURIComponent( url ) );
			}
		}
	}
	
	/**
	 * Set the body of the mail
	 */
	private setMailBody( url: string ) {
		var body = this.lernbuch.book.get( 'title' ) + ' - ' + this.lernbuch.chapter.model.get( 'title' );
		return encodeURIComponent( body ) + '%0D%0A%0D%0A'
			+ encodeURIComponent( url );
	}

	/**
	 * Set the hash of the url to the first visible element
	 */
	private setHash( visibleElement:Element ): string {
		var hash = window.location.hash;
		var newHash = visibleElement.model.uid;
		return ( hash == '' ) ? window.location.toString() + newHash : window.location.href.replace( hash, newHash );
	}
}

export default ShareDialog;