import View from 'ln/view/View';
import Signal from 'ln/signal/Signal';
import Node from 'ln/node/Node';
import template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import Model from 'ln/model/Model';
import IoC from 'ln/ioc/IoC';
import ChapterModel from '../../models/ChapterModel';
import Chapter from '../../elements/Chapter';
import LernBuch from '../../LernBuch';
import NaviChapter from './Chapter';
import NaviChapterElement from './ChapterElement';
import TreeBuilder from './tree/TreeBuilder';
import { IChapterFilter } from './tree/TreeBuilder';
import TreeNode from './tree/TreeNode';
import TreeView from './tree/TreeView';
import Element from '../../elements/Element';

/**
 * Renders a list chapters used for navigation between chapters
 */
class Navigation extends TreeView {

	private treeBuilder:TreeBuilder;
	private treeChapters:TreeNode[];
	private currentChapter:NaviChapter = null;


	constructor( public lernbuch:LernBuch ) {
		
		super();
		this.treeBuilder = new TreeBuilder();
		this.defaultTemplate = 'lb.chapter-navigation'
	}

	/**
	 * Render the template for the chapters
	 */
	public init() {
		
		this.viewList = new ListRenderer<TreeNode>( this.node.js( 'chapters' ) );
		this.viewList.defaultRender( ( model:TreeNode ) => {
			var naviChapter = new NaviChapter( model ).render() as NaviChapter;
			naviChapter.onSelected.add( this.selectChapter, this )
			return naviChapter;
		});

		this.lernbuch.scrollMonitor.change.add( this.onScrolled, this );
		this.lernbuch.chapterChanged.add( this.onChapterChanged, this );

	}


	/**
	 * Render all chapters
	 */
	public renderChapters( chapters:Array<ChapterModel>, options:IChapterFilter ) {

		this.treeChapters = this.treeBuilder.convert( chapters, options );

		this.renderChildren( this.treeChapters );
	}

	/**
	 * Hide all navigations chapters
	 */
	public hideAllNavigationChapters(){
		this.viewList.links.all().forEach( link => {
			link.closeElements();
		});
	}

	/**
	 * Select the given chapter on the lernbuch
	 * @param chapter 
	 */
	public selectChapter( chapter:NaviChapter ){
		this.lernbuch.show( chapter.treeNode.element as ChapterModel );
	}

	/**
	 * Scrollhandler on the lernbuch
	 * @param visibleElements 
	 */
	private onScrolled( visibleElements:Element[] ){
		
		// update path on current chapter
		var currentChapter = this.currentChapter;
		var path = this.currentChapter.checkPathToElement( visibleElements );
		if( !path ) return;
		this.currentChapter.deactivateCurrentPath();
		this.currentChapter.activateCurrentPath( path );

	}


	private onChapterChanged( chapter:Chapter ){
		
		if( this.currentChapter ) this.currentChapter.deactivateCurrentPath();
		
		var current = this.viewList.links.all().filter( link => {
			return link.treeNode.element == chapter.model;
		});

		this.currentChapter = current[0];
	}
	
}

export default Navigation;
