import Node from 'ln/node/Node';
import List from 'ln/list/List';
import Decorator from './Decorator';
import { DecoratorFunction } from './Decorator';
import IoC from 'ln/ioc/IoC';
import AnswerListModel from '../slides/AnswerList/AnswerListModel';
import AnswerListView from '../slides/AnswerList/AnswerList';
import HotSpotModel from '../slides/Hotspot/HotspotModel';
import HotSpotView from '../slides/Hotspot/Hotspot';
import DragDropModel from '../slides/DragDrop/DragDropModel';
import { Drag } from '../slides/DragDrop/DragDropModel';
import DragDropView from '../slides/DragDrop/DragDrop';
import ClozeModel from '../slides/Cloze/ClozeModel';
import ClozeView from '../slides/Cloze/Cloze';
import DropDownModel from '../slides/DropDown/DropDownModel';
import DropDownView from '../slides/DropDown/DropDown';
import OrderModel from '../slides/Order/OrderModel';
import OrderView from '../slides/Order/Order';
import DragDropClozeModel from '../slides/DragDropCloze/DragDropClozeModel';
import DragDropClozeView from '../slides/DragDropCloze/DragDropCloze';
import DragDropSentenceModel from '../slides/DragDropSentence/DragDropSentenceModel';
import DragDropSentenceView from '../slides/DragDropSentence/DragDropSentence';
import FreetextModel from '../slides/Freetext/FreetextModel';
import FreetextView from '../slides/Freetext/Freetext';
import MatrixChoiceView from '../slides/MatrixChoice/MatrixChoice';
import MatrixChoiceModel from '../slides/MatrixChoice/MatrixChoiceModel';
import MatrixBinaryChoiceView from '../slides/MatrixBinaryChoice/MatrixBinaryChoice';
import MatrixBinaryChoiceModel from '../slides/MatrixBinaryChoice/MatrixBinaryChoiceModel';
import SlideView from '../slides/Slide/Slide';
import SlideModel from '../slides/Slide/SlideModel';


export interface EvaluateAnswersConfig {
	markSelected?: boolean;
	markCorrectAnswered?: boolean;
	markWrongAnswered?: boolean;
}

/**
 * A decorator that goes through each answer and marks it either as correct or wrong based on the users selection.<br/>
 */
class EvaluateAnswers extends Decorator {

	private markSelected:boolean;
	private markCorrectAnswered:boolean;
	private markWrongAnswered:boolean;

	public ioc:IoC<DecoratorFunction> = new IoC<DecoratorFunction>();


	constructor( config:EvaluateAnswersConfig = {} ) {
		super();
		this.ioc = new IoC<DecoratorFunction>();
		this.setup();
		
		/**
		 * A flag to evaluate only the ones that are selected by the user
		 */
		this.markSelected = ( typeof config.markSelected != 'undefined' ) ? config.markSelected : false;
		
		/**
		 * This flag defines if the correct answers should be evaluated / marked as correct
		 */
		this.markCorrectAnswered = ( typeof config.markCorrectAnswered != 'undefined' ) ? config.markCorrectAnswered : true;
		
		/**
		 * This flag defines if the wrong answers should be evaluated / marked as wrong
		 */
		this.markWrongAnswered = ( typeof config.markWrongAnswered != 'undefined' ) ? config.markWrongAnswered : true;
	}

	private setup() {
		this.ioc.add( 'default', function( model:AnswerListModel, view:AnswerListView ) {} );
		this.ioc.add( 'SingleChoice', this.answerList );
		this.ioc.alias( 'App\\SingleChoice', 'SingleChoice' );
		this.ioc.add( 'MultipleChoice', this.answerList );
		this.ioc.alias( 'App\\MultipleChoice', 'MultipleChoice' );
		this.ioc.add( 'Hotspot', this.hotSpot );
		this.ioc.alias( 'App\\Hotspot', 'Hotspot' );
		this.ioc.add( 'DragDrop', this.dragDrop );
		this.ioc.alias( 'App\\DragDrop', 'DragDrop' );
		this.ioc.add( 'Cloze', this.cloze );
		this.ioc.alias( 'App\\Cloze', 'Cloze' );
		this.ioc.add( 'DropDown', this.dropdown );
		this.ioc.alias( 'App\\DropDown', 'DropDown' );
		this.ioc.add( 'Order', this.order );
		this.ioc.alias( 'App\\Order', 'Order' );
		this.ioc.add( 'DragDropCloze', this.dragdropcloze );
		this.ioc.alias( 'App\\DragDropCloze', 'DragDropCloze' );
		this.ioc.add( 'DragDropSentence', this.dragdropsentence );
		this.ioc.alias( 'App\\DragDropSentence', 'DragDropSentence' );
		this.ioc.add( 'MatrixChoice', this.matrixchoice );
		this.ioc.alias( 'App\\MatrixChoice', 'MatrixChoice' );
		this.ioc.add( 'MatrixBinaryChoice', this.matrixbinarychoice );
		this.ioc.alias( 'App\\MatrixBinaryChoice', 'MatrixBinaryChoice' );
		this.ioc.add( 'Freetext', this.freetext );
		this.ioc.alias( 'App\\Freetext', 'Freetext' );
	}

	public decorate( slideModel:SlideModel, slideView:SlideView ) {
		var key = slideView.decoratorKey;
		this.ioc.get( slideView.decoratorKey ).call( this, slideModel, slideView );
	}

	/**
	 * Helper function to decorate the correct answer on one node.
	 * @param {Node} node The node to mark either as correct or wrong.
	 * @param {Boolean} isCorrectlySolved A flag if this answer was correctly solved or not.
	 * @param {Boolean} isSelected A flag if the given answer was selected by the user
	 * @protected
	 */
	private decorateAnswer = function( node:Node, isCorrectlySolved:boolean, isSelected:boolean ) {
		
		// always clear decoration first
		this.clearSolutionOn( node );
		
		// filter out decoration if only the selected ones should be decorated.
		if( this.markSelected && !isSelected ) {
			return;
		}
		
		// filter out decoration on nodes that does not correspond to the markedType.
		if( ( isCorrectlySolved && this.markCorrectAnswered ) || ( !isCorrectlySolved && this.markWrongAnswered ) ) {
			// call on superclass
			this.decorateSolutionOn( node, isCorrectlySolved );
		}
	};

	/**
	 * Decorates each answer of the answerList slide if the answer is correct or wrong.
	 */
	private answerList(model:AnswerListModel, view:AnswerListView ) {

		model.answers.forEach( ( answerModel ) => {
			var link = view.answerRenderer.linkOf( answerModel );
			var answerNode = link.node;
			this.decorateAnswer( answerNode, answerModel.correct == answerModel.selected, answerModel.selected );
		});	
	}

	/**
	 * Decorates each user point of the hotSpot slide with correct or wrong.
	 */
	private hotSpot( model:HotSpotModel, view:HotSpotView ) {

		var pointNodes = view.getPointNodes();
		var userPoints = model.userPoints;
		var result = model.partitionUserPoints();
		
		pointNodes.forEach( ( pointNode, index ) => {
			this.decorateAnswer( pointNode, result.correct.indexOf( userPoints[ index ] ) > -1, true );
		});
		
		view.renderHotspotAreas();

	};

	/**
	 * Decorates each drag of a dragdrop slide with correct or wrong
	 */
	private dragDrop( model:DragDropModel, view:DragDropView ) {
		view.dragListRenderer.links.all().forEach( ( link ) => {
			this.clearSolutionOn( link.node );
			if( link.item.dropped > 0 ){
				this.decorateAnswer( link.node, link.item.target == link.item.dropped, true );
			}
		});

	};

	private cloze(model:ClozeModel, view:ClozeView ) {
		view.model.getGapLabels().forEach( function( label ) {
			var node = view.getNodeByLabel( label );
			this.decorateAnswer( node, view.model.hasCorrectInput( label ), true );
		
		}, this);

	};

	private dropdown(model:DropDownModel, view:DropDownView ) {
		view.model.getGapLabels().forEach( function( label ) {
			var node = view.getNodeByLabel( label );
			this.decorateAnswer( node, view.model.hasCorrectInput( label ), true );
		}, this);
	}; 

	private order( model:OrderModel, view:OrderView ) {
		view.dragListRenderer.links.all().forEach( ( link ) => {
			this.decorateAnswer( link.node, link.item.target == link.item.dropped, true );
		});

	};

	private dragdropcloze( model:DragDropClozeModel, view:DragDropClozeView ) {
		view.dragListRenderer.links.all().forEach( ( link ) => {
			var correct = false;
			this.clearSolutionOn( link.node );
			if (link.item.dropped > 0 ){
				var correct = model.drops[link.item.dropped-1].correct.indexOf(link.item.text) >= 0 ? true : false;
				this.decorateAnswer( link.node, correct, false );
			}
		});
	};

	private dragdropsentence( model:DragDropSentenceModel, view:DragDropSentenceView ) {
		var userSolution = "";
		view.dropListRenderer.container.all('.drag-node').forEach( function (item) {
			userSolution += item.html.trim();
		}, this);

		view.dropListRenderer.container.all( '.drag-node' ).forEach( drag => {
			this.decorateAnswer (drag, model.solutions.indexOf(userSolution) >= 0, false ) ;

		});
	};

	private matrixchoice( model:MatrixChoiceModel, view:MatrixChoiceView ) {
		var nodes = view.node.one( '.question-container' ).children();
		model.questions.forEach( ( question, index )=> { 
			var node = nodes[ index ];
			this.decorateAnswer (node, question.correct, true) ;
		});
	};

	private matrixbinarychoice( model:MatrixBinaryChoiceModel, view:MatrixBinaryChoiceView ) {
		var nodes = view.node.one( '.question-container' ).children();
		model.questions.forEach( ( question, index )=> { 
			var node = nodes[ index ];
			this.decorateAnswer (node, question.correct, true) ;
		});
	};

	/**
	 * Decorates freetext (user input required) TODO: make it optional?
	 */
	private freetext( model:FreetextModel, view:FreetextView ) {
		this.decorateAnswer( view.node, model.answered, true );
	};

}

export default EvaluateAnswers;



