import View from 'ln/view/View';
import Node from 'ln/node/Node';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import IoC from 'ln/ioc/IoC';
import { IoCFactoryFunction } from 'ln/list/ListRenderer';
import LernBuch from '../../../LernBuch';
import TreeNode from './TreeNode';


class TreeView extends View {

	public viewList:ListRenderer<TreeNode>;
	public parent:TreeView = null;
	public lernbuch:LernBuch;

	constructor( public treeNode:TreeNode = null ) {
		
		super();
		this.viewList = new ListRenderer<TreeNode>();

	}
    
    public renderChildren( nodes:TreeNode[] ){
        
		this.viewList.selectorFunction = function( item:TreeNode ) {
			return item.element.get<string>( 'modelName' );
		}

		this.viewList.source.fill( nodes );

		this.viewList.links.all().forEach( link => {
			link.parent = this;
		})
	}
	

	/**
	 * Get root of this treeview
	 */
	get root():TreeView{
		return ( this.parent == null ) ? this : this.parent.root;
	}

	protected renderData():any{
		if( this.treeNode ) return this.treeNode.element;
	}

	/**
	 * Activate all nodes on this treeview according to
	 * given path and index
	 * @param path 
	 * @param index 
	 */
	public activateCurrentPath( path: TreeNode[], index:number = 0 ){
		if( path.length > 0 ){
			if( this.treeNode == path[index] ){
				this.node.addClass( '-current' );

				if( this.viewList.source ){
					var link = this.viewList.linkOf( path[index + 1] );
					if( link ) link.activateCurrentPath( path, index + 1 );
				}
			}
		}
	}

	public deactivateCurrentPath(){
		this.node.removeClass( '-current' );
		if( this.viewList.source ){
			this.viewList.links.all().forEach( link => {
				link.deactivateCurrentPath();
			});
		}
	}
}



export default TreeView;
