import IoC from 'ln/ioc/IoC';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import SlideModel from 'lf/slides/Slide/SlideModel';

import {questionElementsIoC} from './elements/questionElementsIoC';

class QuestionContainerView extends View {

        private model;
        elements:ListRenderer<any>;

	constructor( model: any ) {
    	super();
		this.defaultTemplate = 'lb.questioncontainer';
        this.model = model;
    }

    protected init() {

        //render questions
        this.model.get('questions').forEach( (question) => {

            var node = Node.fromHTML(Template.render('lb.question-item', question));
            this.node.js('questions').append(node);

            //listrenderer for question elements
            var questionElements  = new ListRenderer<any>( node.js( "question-element" ) )
            questionElements.ioc = questionElementsIoC;
            questionElements.selectorFunction = function( data:any) {
                return data.modelName;
            };
            questionElements.source = new List (question.get('content'));

        });

    }

    protected renderData() {
        return this.model;
    }

}

export default QuestionContainerView;