
import View from 'ln/view/View';

import LernFragen from 'lf/Lernfragen';
import SlideModel from 'lf/slides/Slide/SlideModel';

import EvaluateAnswersDeco from 'lf/decorators/EvaluateAnswers';
import RelatedFeedbackDeco from 'lf/decorators/RelatedFeedback';
import ShowCorrect from 'lf/decorators/ShowCorrect';
import ModelBasedDeco from 'lf/decorators/ModelBasedDecorator';

import FeedbackButtonDeco from './FeedbackButtonDecorator';

import { mapper } from 'lb/LernBuch';

class Quiz extends View {

    private models: SlideModel[];
    private lernfragen: LernFragen;

	constructor( models: SlideModel[] ) {
		super();
        this.defaultTemplate = 'lf.quiz-element';
        this.models = models;
    }
    
    protected  init() {
        
        this.lernfragen = new LernFragen().defaultSetup();

        //map the unmapped lernfragen
        var slides = this.lernfragen.mapper.model( this.models);

        this.lernfragen.render(this.node.js('lernfragen')) as LernFragen;

        var modelBasedDeco = new ModelBasedDeco();
        modelBasedDeco.default(
            [ 
                new RelatedFeedbackDeco({ posFeedbackProperty: 'posFeedback', negFeedbackProperty: 'negFeedback' }),
            ]
        );
        
        //specific decorators for one model
        modelBasedDeco.set( 'App\\DropDown', 
        [       new RelatedFeedbackDeco({ posFeedbackProperty: 'posFeedback', negFeedbackProperty: 'negFeedback' }),
                new EvaluateAnswersDeco({}),
                new ShowCorrect()
            ]
        );

        this.lernfragen.renderer.userAnswered.add( modelBasedDeco.decorate.bind( modelBasedDeco ) );

        this.lernfragen.renderer.userInput.add(this.removeFeedback.bind(this));

        this.lernfragen.renderer.userAnswered.add( this.setAnswered.bind(this) );
        
        var feedback = new FeedbackButtonDeco();
        this.lernfragen.renderer.slideChanged.add( feedback.decorate.bind( feedback ) );

        var first = (slides.length > 0 )? slides[0] : slides;
        this.lernfragen.renderer.show( first );
    }


    protected removeFeedback() {
        if (this.node.one('.feedback-panel') ) {
            this.node.one('.feedback-panel').remove();
        }
        if ( this.node.one('.dropdown') ) {
            this.node.all('.dropdown').forEach( (dd ) => {
                dd.removeClass('-correct');
                dd.removeClass('-wrong');;
            })
        }
    }
    //set the question as answered
    protected setAnswered() {
        this.item.answered = true;
    }
    
    protected renderData() {
        return this.models;
	}
}

export default Quiz;

