import IoC from '../ioc/IoC';
import Model from '../model/Model';
import View from '../view/View';
import { List, ListAddedListener, ListFilledListener, ListRemovedListener } from '../list/List';
import Node from '../node/Node';
import Signal from '../signal/Signal';
import Template from '../template/TemplateManager';

/**
 * Specific interface for closure function used in the ioc
 */
export interface IoCFactoryFunction<T> {
	( item:T, index?:number ): { node:Node };
}

/**
 * A function that defines how to a map the registered keywords in the ioc with the item:T
 * Example if T is Model:
 * 		function( model:Model ) {
 * 			return model.modelName;
 * 		}
 *  
 */
export interface IoCSelectorFunction<T> {
	( item: T ): string;
}


/**
 * An interface for links that combine the node and its item
 */
export interface ILink<T> {
	node: Node;
	item: T;
}

/**
 * An interface that defines what is required as a source for the ListRenderer 
 */
export interface ListRendererSource<T> {
	added:Signal<ListAddedListener<T>>;
	removed:Signal<ListRemovedListener<T>>;
	filled:Signal<ListFilledListener<T>>;
	sorted:Signal<ListFilledListener<T>>;
	all():T[];
	fill( data:T[] );
}


/**
  * Renders a list with items of type T into a template. Listens on list signals for rerendering.
  * The rendering of the list items is defined through an ioc for simpler usage with a render function.
  * 
  * @example
  * ```typescript
  * 
  * // Example 1
  * // Render a list of strings
  * 
  * // Instantiate the ListRenderer and provide a node the listRenedrer is rendered to
  * var listRenderer = new ListRenderer( Node.js( 'targetNode' ) );
  * 
  * // Define the closure a list item is rendererd with
  * listRenderer.defaultRender( function( item:string ) {
  * 	var myView = new MyView( item ).render();
  * 	return { node: myView.node }
  * });
  * 
  * // Fill in the source with items. This invokes the render function of the listRenderer
  * listRenderer.source.fill( [ 'one', 'two', 'three' ] );
  * 
  * // Append a new element. The list is rerenderer after all list manipulations
  * listRenderer.source.append( 'four' );
  * 
  * 
  * 
  * // Example 2
  * // Render models 
  * 
  * // Instantiate a ListRenderer with type "Model"
  * var listRenderer = new ListRenderer<Model>( Node.js( 'targetNode' ) );
  * 
  * // Define how a Title model is rendered
  * listRenderer.renderer.ioc.add( 'Title', function( model:TitleModel ) {
  * 	var titleView = new TitleView( model ).render();
  * 	return { node: titleView.node }
  * });
  *
  * // Define how a Paragraph model is rendered
  * listRenderer.renderer.ioc.add( 'Paragraph', function( model:ParagraphModel ) {
  * 	var paragraphView = new ParagraphView( model ).render();
  * 	return { node: paragraphView.node }
  * });
  * 
  * // Define how to distinguish between the models
  * // This step is optional in this case. The selectorFunction is predefined
  * listRenderer.selectorFunction = function( model:Model ) {
  * 	return model.get<string>( 'modelName' );
  * }
  *
  * // Fill the source of the ListRenderer with models. 
  * listRenderer.source.fill( [ new TitleModel(), new ParagraphModel(), new ParagraphModel() ] )
  *
  * 
  *  
  * // Example 3
  * // Use a ModifiableList<T> instead of a List<T> as source.
  * // A source must implement the ListRendererSource interface.
  * // Fallback source is a List<T>
  * 
  * // Instantiate a ListRenderer with type string, containig a source with type ModifiableList<string>
  * var listRenderer = new ListRenderer<string, ModifiableList<string>>( Node.js( "content" ) );
  * 
  * // Define the closure a list item is rendererd with
  * listRenderer.defaultRender( function( item:string ) {
  *		var node = Node.fromTag( 'div' );
  *		node.html = item;
  *		return { node: node };
  *	});
  *
  * // Instantiate a ModifiableList as new source
  * listRenderer.source = new ModifiableList<string>();
  *
  * // Fill in the source 
  * listRenderer.source.fill( [ "eins", "zwei", "drei", "vier", "fünf", "sechs" ] );
  * 
  * // Define a modifier for the ModifiableList
  * // Filter the list in order to display only items with less than 5 letters
  * // Appending a modifier applies the modifier and rerenders the list
  * listRenderer.source.modifiers.append( function( items ) {
  * 	return items.filter( ( item ) => {
  * 		return item.length < 5;
  * 	});
  * });
  * 
  *```
 */
export class ListRenderer<T,G extends ListRendererSource<T> = List<T>> {

	public ioc: IoC<IoCFactoryFunction<T>> = new IoC<IoCFactoryFunction<T>>();
	public selectorFunction: IoCSelectorFunction<T>;
	public links = new List<ILink<T>>();
	public container:Node;

	protected _source:G;
	 
	constructor( container?:Node ) {
		this.container = container;

		// the default selector function to use without ioc definitions.
		this.selectorFunction = function( item:T ) {
			return ( item instanceof Model ) ? item.get<string>( 'modelName' ) : 'default';
		}

		this.source = new List<T>( [] );
	}
	
	get source():G {
		return this._source;
	}
	
	set source( items:G ) {
		if( this._source ) this.removeListeners();
		this._source = items;
		this.addListeners();
		
		if( this.container ) this.onFilled( items.all() );
	}

	public defaultRender( closure:IoCFactoryFunction<T> ) {
		this.ioc.add( 'default', closure );
	}
	
	protected addListeners(){
		this._source.filled.add( this.onFilled, this );
		this._source.added.add( this.onAdded, this );
		this._source.removed.add( this.onRemoved, this );
		this._source.sorted.add( this.onSorted, this );
	}
	
	protected removeListeners(){
		this._source.filled.remove( this.onFilled, this );
		this._source.added.remove( this.onAdded, this );
		this._source.removed.remove( this.onRemoved, this );
		this._source.sorted.remove( this.onSorted, this );
	}
	
	protected renderList( items:T[] ) {

		var fragment = document.createDocumentFragment();
		
		items.forEach( function( item, index ) {

			var obj = <ILink<T>>this.ioc.get( this.selectorFunction( item ) )( item, index );
			obj.item = item; // inject item
			this.links.append( obj );

			// add to fragment
			fragment.appendChild( ( obj.node instanceof Node ) ? obj.node.native : obj.node );
		}, this);

		// append fragment to container.
		this.container.append( fragment );
	}

	protected onFilled( items:T[] ) {
		// remove all links
		this.links.all().forEach( link => {
			link.node.remove();
		});
		
		this.links.empty();	
		this.renderList( items );
	}

	protected onAdded( newItem:T, newIndex:number ) {
		var obj = <ILink<T>>this.ioc.get( this.selectorFunction( newItem ) )( newItem, newIndex );
		obj.item = newItem;
		this.container.insert( obj.node, newIndex );
		this.links.add( obj , newIndex );
	} 

	protected onRemoved( removedItem:T, index:number ) {
		var link = this.links.get( index );
		this.links.removeAt( index );
		this.container.removeChild( link.node );
		link.node = undefined;
	}

	protected onSorted( items:T[] ) {
		var links = [];
		for( var i = 0; i < items.length; i++ ) {
			var link = this.linkOf( items[i] );
			this.container.insert( link.node, i );
			links.push( link );
		}
		this.links.fill( links );
	}

	public linkOf( item:T | Node ):ILink<T> {

		// define on which attribute to check
		var attr = ( item instanceof Node ) ? "node" : "item";

		return this.links.find( function( link ) {
			return link[attr] === item; 
		});
	}
}

export default ListRenderer;
