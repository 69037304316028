// import lib


//TODO: Why doesnt this work with the main ts-lib (ln\...) and only with the one inside lernbuch???

import Node from 'ln/node/Node'; 
import LinkedList from'ln/linkedlist/LinkedList'; 
import Template from'ln/template/TemplateManager';
import Window from 'ln/node/Window';
import ModifiableList from 'ln/list/ModifiableList';
import ListRenderer from 'ln/list/ListRenderer';

 
// import project


import ChapterFilter from './ChapterFilter';
import BookContent from './BookContent';
import CommonPage from './CommonPage';


import el_templates from './templates/elements';
import lb_templates from './templates/lernbuch'; 
import slide_templates from './templates/slides'; 
import lernfragen_templates from './templates/lernfragen'; 


Template.add( el_templates );
Template.add( lb_templates );
Template.add( slide_templates );
Template.add( lernfragen_templates );

Template.context.image = function( id ) {
	return id;
};


export function setup( basePath ) {
	Template.context.basePath = basePath;
	Template.context.assets = 'asset';
	
	Template.context.assetsPath = Template.context.basePath  + Template.context.assets + '/';
	Template.context.contentPath =  Template.context.basePath + 'show/';
	Template.context.exportPath =  Template.context.basePath + 'exports/';
	
	Template.context.imagePath = function( id:number, preset:string = 'medium', ext:string = 'jpg' ) {
		return Template.context.assetsPath + id + '/' + preset +'.'+ ext;
	}

	//main navigation
	Node.one( '.main-navigation > .hamburger' ).click.add( ( node )=>{
		node.parent().all('.level1').forEach( ( child ) => {
			child.removeClass('-active')
			child.toggleClass('-close')
			if (child.one('.level2')){
				child.one('.level2').addClass('-close');
			}
		})
	});
	
	Node.all('.level1').forEach( ( node ) => {
		node.click.add( ( child )=> {
			child.toggleClass('-active');
			if (child.one('.level2')){
				child.one('.level2').toggleClass('-close');
			}

			//close the rest
			node.parent().all('.level1').forEach( (level1) =>{
				if (level1 != node) {
					level1.removeClass('-active');
					if (level1.one('.level2')) {
						level1.one('.level2').addClass('-close');
					}
				}
			})
		});
	});

	//global scroll monitor for header
	window.addEventListener('scroll', function(e) {
		
		let scrollPosition = window.pageYOffset;

		if (scrollPosition > 200) {
			Node.one('.page-header').addClass('-collabse');
			if ( Node.one('.sidebar-teacher')  ) {
				Node.one('.sidebar-teacher').addClass('-collabse');
			}
		}
		else {
			Node.one('.page-header').removeClass('-collabse');
			if ( Node.one('.sidebar-teacher')  ) {
				Node.one('.sidebar-teacher').removeClass('-collabse');
			};
		}

		// prevent filter from overlapping with footer
		let windowSize     = window.innerHeight;
		let bodyHeight     = document.body.offsetHeight;
		let filterContainerNode = Node.one('.filter-container');
		if (filterContainerNode) {
			let distanceToBottom = Math.max(bodyHeight - (scrollPosition + windowSize), 0);
			if (distanceToBottom < 100) { 
				filterContainerNode.style.bottom = '90px';
			}
			else {
				filterContainerNode.style.bottom = '0px';
			}
		}


	})

	//close menu if clicked outside
	//https://stackoverflow.com/questions/152975/how-do-i-detect-a-click-outside-an-element
	
	const outsideClickListener = event => {
		var menuNode = Node.js('main-navigation').native;
		if (!menuNode.contains(event.target)) { // or use: event.target.closest(selector) === null
			if (isVisible(menuNode)) {
				//menuNode.style.display = 'none'
				Node.js('main-navigation').all('.level1').forEach( (node) =>{
					node.addClass('-close');
				} )
				//removeClickListener()
			}
		}
	}

	// const removeClickListener = () => {
	// 	document.removeEventListener('click', outsideClickListener)
	// }

	document.addEventListener('click', outsideClickListener)
	
	
	const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ) // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js 

}

export function common( data ) {
	new CommonPage( data );
}

export function overview( data ) {
	new ChapterFilter( data );
}

export function content ( data, slug ) {
	new BookContent( data, slug )

	//set current chapter in navi depending on scroll position
	var sidebarElements = document.querySelectorAll('.container-teacher >.title.-chapter')
	var contentElements = document.querySelectorAll('.container-questions .main-title');
	var previousElement = contentElements[0];
	sidebarElements[0].classList.add('-current')

	window.addEventListener('scroll', function(e) {
		var visible = [];
		contentElements.forEach( (element) => {
			if ( isInViewport(element) )
			visible.push( element);
		})

		let current = visible[visible.length -1 ]
		if (current && current != previousElement) {
			previousElement = current;
			
			sidebarElements.forEach( (element)=> {
				element.classList.remove('-current')
			});

			document.querySelector(' [data-uid="' + current.parentElement.id + '"]').classList.add('-current');
		}
	});
	
	var isInViewport = function (elem) {
		var bounding = elem.getBoundingClientRect();
		return (
			bounding.top <= (window.innerHeight || document.documentElement.clientHeight) 
		);
	};

}
