import IoC from 'ln/ioc/IoC';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

import setup, { SetupConfig } from 'ln/setup/setup';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import SlideModel from 'lf/slides/Slide/SlideModel';


import Element from 'lb/elements/Element'

export  class ImageView extends Element {

    readonly model;
    scrollPosition: number;

    constructor(model) {
        super( model );
        
    }

    init() {
        this.scrollPosition = 0;

        if (this.node.js('lightbox')) {
            this.node.js('open').click.add( function()  {
                this.scrollPosition = window.scrollY;
                this.node.one('.lightbox').addClass('-open');
                document.body.style.position = 'fixed';
                //this.node.one('.image').setAttribute('src',  Template.context.imagePath(this.data.image.id, 'large')  )
            }.bind(this));

            this.node.js('close').click.add( function() {
                this.node.one('.lightbox').removeClass('-open');
                document.body.style.position = 'static';
                window.scroll(0, this.scrollPosition);
                //this.node.one('.image').setAttribute('src',  Template.context.imagePath(this.data.image.id, 'medium')  )
            }.bind(this));

        }
    }

}