import Node from 'ln/node/Node';
import View from 'ln/view/View';
import Signal from 'ln/signal/Signal';
import template from 'ln/template/TemplateManager';
import setup from 'ln/setup/setup';
import SearchInput from './SearchInput';
import SearchResult from './SearchResult';
import LernBuch from '../LernBuch';

/**
 * SearchOutput Class.
 */
class SearchOutput extends View{

	public resultTemplate:string;
	
	/**
	 * Instantiates a SearchOutput
	 */
	constructor( public lernbuch:LernBuch, template:string = "lb.search-output", resultTemplate:string = 'lb.search-output-result' ) {
		super();
		this.defaultTemplate = template;
		this.resultTemplate = resultTemplate;
	}

	public listenTo( input:SearchInput ){
		input.results.add( function( term:string, results:SearchResult[] ){
            if( results ){
                this.node.addClass( '-open' );
                this.renderResults( term, results )
            } else {
                this.node.removeClass( '-open' );
			}
		}.bind( this ));
	}
	
	public renderResults( term:string, searchResults:Array<SearchResult> ){
		var outputList = this.node.js( 'list' );
		outputList.empty();
		for( var i = 0; i < searchResults.length; i++ ) {
			var result = new SearchOutputResult( searchResults[i] ).render() as SearchOutputResult;
			result.selected.add( this.onResultSelected, this );
			outputList.append( result.node );
		}
	}

	private onResultSelected( result:SearchResult ){
		this.node.removeClass( '-open' );
		this.lernbuch.show( result.chapter, result.element );
	}
}

export default SearchOutput;

interface SearchOutputCallback {
	( result:SearchResult );
}

class SearchOutputResult extends View {

	public selected:Signal<SearchOutputCallback>;

	constructor( public result:SearchResult, template:string = 'lb.search-output-result'){
		super();
		this.defaultTemplate = template;

		this.selected = new Signal<SearchOutputCallback>();

	}

	public init(){
		this.node.click.add( this.onSelected, this );
	}
	
	protected renderData(){
		return this.result;
	}
	
	private onSelected(){
		this.selected.dispatch( this.result );
	}
}
