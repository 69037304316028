import ElementModel from './ElementModel';
import GalleryImageModel from './GalleryImageModel';

class GalleryModel extends ElementModel {
	
	get images(){
		return this.get<Array<GalleryImageModel>>( 'images' );
	}
	
	set images( images:GalleryImageModel[] ){
		this.set( 'images', images );
	}
}

export default GalleryModel;