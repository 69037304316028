import LinkedList from 'ln/linkedlist/LinkedList';
import { mixin } from  'ln/js';
import ElementModel from './ElementModel';

class GalleryImageModel extends ElementModel implements LinkedList {
	
	next:LinkedList;
	previous:LinkedList;
	
	get caption():string{
		return this.get<string>( 'caption' );
	}
	
	get image(){
		return this.get<string>( 'image' );
	}
	
	/**
     * Add mixins functions
     */
    getFirst: () => LinkedList;
    getLast: () => LinkedList;
    addNext: ( node:LinkedList ) => void;
    addPrevious: ( node:LinkedList ) => void;
    toArray: () => LinkedList[];
}

mixin( GalleryImageModel, [ LinkedList ] );

export default GalleryImageModel;