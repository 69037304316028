import View from 'ln/view/View';
import Signal from 'ln/signal/Signal';
import IoC from 'ln/ioc/IoC';
import { FactoryFunction } from 'ln/view/Scanner'; 
import { ILink } from 'ln/list/ListRenderer';
import Mapper from 'ln/model/Mapper';
import { scanner } from 'ln/view/Scanner';
import LinkedList from 'ln/linkedlist/LinkedList';
import setup from 'ln/setup/setup';
import BookModel from './models/BookModel';
import ChapterModel from './models/ChapterModel';
import ElementModel from './models/ElementModel';
import ScrollMonitor from './ScrollMonitor';
import Element from './elements/Element';
import Chapter from './elements/Chapter';
import ChapterNavigation from './navigation/chapter/Navigation';
import SearchInput from './search/SearchInput';
import SearchOutput from './search/SearchOutput';
import OutlineNavigation from './navigation/outline/OutlineNavigation';


/**
 * Interface for all listener function that listen on the top signal
 */
export interface ChapterChangedCallback {
    ( chapter:Chapter );
}

export var mapper = new Mapper();



/**
 * This class setups all required elements for a default lernbuch application.
 * It instantiates the LernBuchView that renders the chapters and elements.
 * It instantiates a backend that gets all the configurations for the chapters and elements 
 */
export class LernBuch extends View {

	public book:BookModel;
	public scrollMonitor:ScrollMonitor;

	private _currentChapter:Chapter = null;

	// signal for chapter change event
	public chapterChanged = new Signal<ChapterChangedCallback>();
	public elementChanged = new Signal<ChapterChangedCallback>();

	constructor( data:any ) {
		
		super( { template:'lb.lernbuch' } );

		this.scrollMonitor = new ScrollMonitor();
		this.book = mapper.model( data ) as BookModel;
		
	}
	
	init() {
		scanner.scan( this.node, this, [ 'search-input', 'search-output' ] );
	}

	/**
	 * Define data to render
	 */
	protected renderData(){
		return this.book;
	}

	/**
	 * Show the chapter by the given model
	 * @param chapter ChapterModel
	 * @param element ElementModel
	 */
	public show( chapter:ChapterModel, element:ElementModel = null ) {
		
		if( !this.chapter || this.chapter.model != chapter ){

			
			this.node.js( 'chapter' ).empty();
			
			this.chapter = new Chapter( chapter, this ).render( this.node.js( 'chapter' ) ) as Chapter;

			// dispatch chapter changed event
			this.chapterChanged.dispatch( this.chapter );

			// reset scrollMonitor
			this.scrollMonitor.elements = this.chapter.elements.links.all().map( ( link ) => {
				return link as any;
			});
			
		} else {

			// no chapter change only element change
			this.elementChanged.dispatch( this.chapter );
		}

		this.updateURL( chapter, element );
		this.scrollMonitor.scrollToElementHash();
	}

	/**
	 * This function resolves the chapter / element model based on the given slug and uid and passes them to the show function
	 * @param chapterSlug The slug of the chapter to show
	 * @param elementUid The optional element uid to show
	 */
	public showSlug( chapterSlug:string, elementUid:string = window.location.hash.slice( 1 ) ) {

		// solve chapter based on slug
		var currentChapter = this.book.chapterOf( chapterSlug );
		// fallback to first chapter if its not found
		if( !currentChapter ) currentChapter = this.book.chapters[0];

		var element = currentChapter.getElement( elementUid );

		this.show( currentChapter, element );
	}


	private updateURL( chapter:ChapterModel, element:ElementModel ){
		var newLocation = setup.route( 'chapter', { book: this.book.slug, chapter: chapter.slug, uid: (element) ? element.uid : '' } ).url();
		window.history.pushState( null, chapter.slug, newLocation );
	}
	
	get chapter():Chapter {
		return this._currentChapter;
	}

	set chapter( chapter:Chapter ){
		this._currentChapter = chapter;
	}
	
	get navigation():ChapterNavigation {
		return scanner.first( 'navigation', this.node ) as ChapterNavigation;	
	}

	get outlineNavigation():OutlineNavigation {
		return scanner.first( 'outline-navigation', this.node ) as OutlineNavigation;
	}
	
	get searchInput():SearchInput {
		return scanner.first( 'search-input', this.node ) as SearchInput;
	}
	
	get searchOutput():SearchOutput {
		return scanner.first( 'search-output', this.node ) as SearchOutput;
	}
}

export default LernBuch;