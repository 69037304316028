import ChapterModel from '../../../models/ChapterModel';
import TreeNode from './TreeNode';
import ElemetModel from '../../../models/ElementModel';

export interface IFilteredChapters{
    root:TreeNode,
    children: TreeNode[]
}

export interface IChapterFilter{
    modelNames:string[],
    level:number
}

class TreeBuilder {

    private startLevel = 1;
    private filteredChapters

    public convert( chapters:ChapterModel[], options:IChapterFilter ){
        this.filteredChapters = this.filter( chapters, options );

        var hierarchyChapters:TreeNode[] = [];
        this.filteredChapters.forEach( chapter => {
            var result = this.build( chapter.root, chapter.children );
            hierarchyChapters.push( result );
        });

        return hierarchyChapters;

    }
    
    private filter( chapters:ChapterModel[], options:IChapterFilter ){
        
        var filteredChapters:IFilteredChapters[] = [];

        chapters.forEach( chapter => {
            var root = new TreeNode( chapter );
            var children = [];
            chapter.elements.all().forEach( element => {
                if( options.modelNames.indexOf( element.modelName ) > -1 && element.get( 'level', 0 ) <= options.level ){
                    children.push( new TreeNode( element ))
                }
            });
            filteredChapters.push( { root:root, children: children } );
        });

        return filteredChapters;
    }

    private buildHierarchy( current:TreeNode, elements:TreeNode[], level:number ){
        // if we are still on the startlevel and have no level specified - just return current 
        if( current.getLevel() == undefined && level == this.startLevel ){
            return current;
        } 

        while( elements.length > 0 ){
            var next = elements.shift();
            if( next.getLevel() > level ){
                
                // add first to current
                current.addChild( next );
                next = this.buildHierarchy( next, elements, next.getLevel() );

            } else if ( next.getLevel() == undefined ){
                
                current.addChild( next );

            } else {
                
                // put element back on stack and go level back
                elements.unshift( next );
                break;

            }

        }

        current.assignParent();

        return current;

    }

    private build( chapter:TreeNode, elements:TreeNode[] ){
        while( elements.length > 0 ){
            chapter.addChild( this.buildHierarchy( elements.shift(), elements, this.startLevel ) );
            chapter.assignParent();
        }
        return chapter;
    }

}

export default TreeBuilder;