import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Window from 'ln/node/Window';
import LernBuch from '../../LernBuch';
import ChapterModel from '../../models/ChapterModel';
	
/**
 * Renders a link to the previous chapter at the top of the chapter and a link to the next chapter at to bottom of the chapter
 */
class PagingNavigation extends View {

	constructor( public lernbuch:LernBuch, template:string = 'lb.paging-navigation' ) {
		
		super();
		
		this.defaultTemplate = template;

		// register on window events
		Window.resize.add( this.updateVisibility, this );
		Window.scroll.add( this.updateVisibility, this );

		// listen to chapter changes
		this.lernbuch.chapterChanged.add( this.updateLink, this );

	}

	/**
	 * Define things after render
	 */
	public init(){

		// register on the click for the next chapter
		this.node.click.add( this.onClick, this ); 

	}

	/**
	 * Define data to render
	 */
	protected renderData(){
		return { title: 'no next chapter yet' };
	}
	
	private updateLink() {
		
		// get the model of the current chapter
		var currentChapter = this.lernbuch.chapter.model;
		
		// get the next chapter if existing
		var nextChapter:ChapterModel = currentChapter.next as ChapterModel;

		if( nextChapter != undefined ) {
			
			// set the title of the next chapter
			this.node.js( 'chaptertitle' ).html = nextChapter.title;
			this.node.removeClass( 'hidden' );

		} else {
			
			this.node.addClass( 'hidden' );

		}
	}

	/**
	 * Update visibility according to scroll position of window
	 */
	private updateVisibility() {
		
		var scrollInfo = Window.scrollInfo();

		// hide or show the buttons
		if( scrollInfo.height <= scrollInfo.top ) {
			this.node.addClass( '-show' );
		}else {
			this.node.removeClass( '-show' );
		}
	}
	
	/**
	 * Callback when one of the navigation items was clicked.
	 */
	private onClick( node:Node, event:Event ) {
		
		var nextChapter = this.lernbuch.chapter.model.next as ChapterModel;
		this.lernbuch.show( nextChapter );

	}
	
}

export default PagingNavigation;
	