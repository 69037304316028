import IoC from 'ln/ioc/IoC';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

import setup, { SetupConfig } from 'ln/setup/setup';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import SlideModel from 'lf/slides/Slide/SlideModel';

import {ImageView} from './ImageView';

import Element from 'lb/elements/Element';

export  class CarouselView extends Element {

    readonly model;
    scrollPosition: number;
    lightboxes:Node[];
    position: number
    step: number;

    constructor(model) {
        super( model );
    }

    init() {
        this.data.scrollPosition = 0;
        this.data.position = 0;
        this.onImageLoaded();
        this.node.js('right').click.add( this.right.bind(this));
        this.node.js('left').click.add( this.left.bind(this) );

        this.node.js('close').click.add( function() {
            this.node.removeClass('-lightbox')
            document.body.style.position = 'static';
            window.scroll(0, this.data.scrollPosition);
        }.bind(this));

    }

    registerLightBox(element) {
        element.js('open').click.add( function(e) {
            this.data.scrollPosition = window.scrollY;
            document.body.style.position = 'fixed';
            this.node.addClass('-lightbox')
        }.bind(this));


    }

    onImageLoaded() {
        let all = this.node.all('img');
        let counter = all.length;

        all.forEach( function (image)  {
            image.native.addEventListener('load', (e)=> {
                counter--;
                if (counter === 0 ) {
                    this.data.lightboxes = this.node.all('.lightbox');
                    

                    // window.addEventListener('resize', ()=> {
                    //     // this.initCarouselSize();
                    // });

                    // //todo: this is probably not the best idea..
                    // Node.one('.button-teacher').click.add( ( node )=>{
                    //     // this.initCarouselSize();
                    // })

                    this.data.lightboxes.forEach( function(element) {
                        if (element.parent().js('lightbox') ) {
                            this.registerLightBox( element );
                        }
                    }.bind(this))

                }
            })
        }.bind(this))
    }

    checkNavigation() {
        this.node.js('left').parent().style.display = this.data.position > 0 ? 'block' : 'none';
        this.node.js('right').parent().style.display = this.data.lightboxes.length > ( this.data.position + 1) ? 'block' : 'none';
    }

    left() {
        this.data.position = this.data.position - 1;
        this.updatePosition();
    }

    right() {
        this.data.position = this.data.position + 1;
        this.updatePosition();
    }

    // initLightboxSize() {
    //     this.data.step = 100;
    //     this.updatePosition();
    // }

    // initCarouselSize() {
    //     //this.data.step =  this.node.one('.lightbox').native.getBoundingClientRect().width / Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 100;
    //     //this.data.step = this.data.position*100;
    //     this.updatePosition();
    // }
    
    updatePosition() {
        this.node.one('.inner-stage').style.left = -( this.data.position * 100) + '%';
        this.checkNavigation();
    }


}