// import lib
import Model from 'ln/model/Model';
import LinkedList from 'ln/linkedlist/LinkedList';

// import project
import ChapterModel from './ChapterModel';
import ElementModel from './ElementModel';

class BookModel extends Model {
	
	get title():string {
		return this.get<string>( 'title' );
	}
	
	get slug():string {
		return this.get<string>( 'slug' );
	}
	
	get chapters():Array<ChapterModel> {
		return this.get<Array<ChapterModel>>( 'chapters' );
	}
	
	set chapters( chapters: Array<ChapterModel> ){
		this.set( 'chapters', chapters );
	}
	
	
	/**
		* Returns the chapter model for the book with the given slug
		*/
	public chapterOf( slug:string ):ChapterModel {

		// find the chapter with the given id.
		var found = this.chapters.filter( function( chapter:ChapterModel ) {
			return chapter.slug === slug;
		});
		
		return found[0];
	}
}

export default BookModel;