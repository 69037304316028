// import lib
import LinkedList from 'ln/linkedlist/LinkedList';
import List from 'ln/list/List';
import { mixin } from 'ln/js';

// import project
import ElementModel from './ElementModel';
import BookModel from 'lb/models/BookModel';

class ChapterModel extends ElementModel implements LinkedList {
    
        next:LinkedList;
        previous:LinkedList;
        
        /**
         * returns an element with the given uid of this chapter
         */
        getElement( uid ):ElementModel {
            
            // find the element with the given id on the chapter config.
            var found = this.elements.all().filter( function( element:ElementModel ) {
                return element.uid == uid;
            });
            
            return found[0];
        }

        
        get title():string {
            return this.get<string>( 'title' );
        }

        get style():string {
            return this.get<string>( 'style' );
        }

        get subtitle():string {
            return this.get<string>( 'subtitle' );
        }
        
        get elements():List<ElementModel> {
            return this.get<List<ElementModel>>( 'elements' );
        }
	
		set elements( el: List<ElementModel> ){
			this.set( 'elements', el );
		}
		
        get slug():string {
            return this.get<string>( 'slug' );
        }

        get book():BookModel {
            return this.get<BookModel>( 'parent' );
        }
        
        /**
         * Add mixins functions
         */
        getFirst: () => LinkedList;
        getLast: () => LinkedList;
        addNext: ( node:LinkedList ) => void;
        addPrevious: ( node:LinkedList ) => void;
        toArray: () => LinkedList[];
}

mixin( ChapterModel, [ LinkedList ] );

export default ChapterModel;