import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import Window from 'ln/node/Window';
import LernBuch from '../../LernBuch';
import Element from '../../elements/Element';
import Chapter from '../../elements/Chapter';
import BookModel from '../../models/BookModel';
import ChapterModel from '../../models/ChapterModel';


/**
 * renders all elements of all chapters into a line to quick navigation through them
 */
class OutlineNavigation extends View {
	
	private elements:Array<Node> = [];
	private lernbuch:LernBuch;

	constructor( lernbuch:LernBuch, template:string = 'lb.outline-navigation' ) {
		super();
		
		this.lernbuch = lernbuch;
		this.defaultTemplate = template;

	}

	public init(){

		// replace the outlineNavigation
		this.lernbuch.node.one( '.outline-navigation' ).replace( this.node );

		// register on change event of scrollMonitor
		this.lernbuch.scrollMonitor.change.add( this.update, this );

		// listen on chapter changed to proper update even if no element is visible
		this.lernbuch.chapterChanged.add( this.onChapterChange, this );

	}

	/**
	 * Chapter change handler
	 * @param chapter 
	 */
	private onChapterChange( chapter:Chapter ) {
		if (chapter.elements.links.first() != null) {
			this.update( [ ( chapter.elements.links.first() as any ) ] ) ;
		}
	} 
	
	/**
	 * Updates the outline navigation based on the visible element of the lernbuch
	 */
	private update( visibleElements:Array<Element> ) {
		
		// check for proper visible elements
		if( visibleElements && visibleElements.length > 0 ) {
			
			// now get the ration of the vertical scrollbar to select visible element based on scrollbar position
			var scrollInfo = Window.scrollInfo();
			// check for division by zero
			var ratio = ( scrollInfo.height != 0 ) ? ( scrollInfo.top / scrollInfo.height ) : 0;
			
			// clamped index based on scrollbar ratio
			var index = Math.min( Math.max( Math.round( ratio * ( visibleElements.length - 1 ) ), 0 ), visibleElements.length - 1 );
			this.selectElement( visibleElements[ index ] );
		}
	}
	
	/**
	 * Selects only the outline element based on the given element
	 */
	public selectElement( element:Element ) {
		// search for the elment with the given model
		var found = this.elements.filter( ( current ) => {
			return current.getAttribute( 'name' ) == element.model.uid;
		});
		
		// remove active class on all elements
		this.node.all( '.element' ).forEach( ( node:Node ) => {
			node.removeClass( '-active' );
		});
		
		// add active class to element
		if( found && found.length > 0 ) found[0].addClass( '-active' );
	}
	
	
	/**
	 * Renders all chapters and elements
	 */
	public renderBook( book:BookModel ):void {
		
		// loop over chapters and elements
		book.chapters.forEach( ( chapter:ChapterModel ) => {
			
			var chapterNode = Node.fromHTML( Template.render( 'lb.outline-chapter', chapter ) );
			
			chapter.elements.all().forEach( ( element ) => {
				
				var elementNode = Node.fromHTML( Template.render( 'lb.outline-element', element ) );
				elementNode.setAttribute( 'name', element.uid );
				this.elements.push( elementNode );
				
				chapterNode.append( elementNode );
			});
			
			this.node.append( chapterNode );
		});
	}
}

export default OutlineNavigation;