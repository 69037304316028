import ElementModel from '../models/ElementModel';
import SearchResult from './SearchResult';

/**
 * SearchPattern Class.
 */
class SearchPattern {

	
	public search( term:string, element:ElementModel ):SearchResult {
		var result:SearchResult = new SearchResult( element );
		return null;
	}
}

export default SearchPattern;