import Model from 'ln/model/Model';
import ChapterModel from 'lb/models/ChapterModel';

/**
 * This class generates a slug based on the id and model name
 */
class ElementModel extends Model {
	
	constructor( json:Object ){
		super( json );
	}
	
	get modelName():string {
		return this.get<string>( 'modelName' );
	}
	
	get id():number {
		return this.get<number>( 'id' );
	}
	
	get uid():string {
		if( super.object().uid ) return super.object().uid + this.id;
		var prefix = this.modelName.substring( 0, 2 );
		return prefix + this.id;
	}

	get chapter():ChapterModel {
		return this.get( 'chapter' );
	}
	
	object() {
		var obj:any = super.object();
		if( !obj.uid ) obj.uid = this.uid;
		
		return obj;
	}
}

export default ElementModel;