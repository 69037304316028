// import lib

import setup from 'ln/setup/setup';
import Node from 'ln/node/Node'; 
import LinkedList from'ln/linkedlist/LinkedList'; 
import Template from'ln/template/TemplateManager';
import Window from 'ln/node/Window';
import ModifiableList from 'ln/list/ModifiableList';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import Model from 'ln/model/Model';

// import project
import { mapper } from 'lb/LernBuch';
import { ioc as elements } from 'lb/elements/Chapter';

import Chapter from 'lb/elements/Chapter';

import { defaultsetup } from 'lb/setup/defaultsetup';

import { models, lernbuch, chapter, chapterNavi } from 'lb/setup/defaultsetup';


import BookModel from'lb/models/BookModel';

import ChapterModel from 'lb/models/ChapterModel';
import ElementModel from'lb/models/ElementModel';

import Element from 'lb/elements/Element';

import QuestionContainerView from './QuestionContainerView';

import {questionElementsIoC} from './elements/questionElementsIoC';



class CommonPage {

    data:{};
    elementList: ListRenderer<any>;
    
    
    constructor( data  ) {
        this.data = data;
        
        this.init();
    }
    
    init() {
        mapper.toModel.add( 'default', function( json:any ){
            return new ElementModel( json ) ;
         });
        var page = mapper.model( this.data ) as BookModel;

        this.elementList = new ListRenderer<any>( Node.one( '#common-page' ) );

        this.elementList.defaultRender( function ( model:ElementModel ) {
            return new Element( model ).render() as Element;
        });
        
        this.elementList.source = new List(page.get('elements'));
    }

    
}

export default CommonPage;