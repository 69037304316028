// import lib
import Node from 'ln/node/Node';
import LinkedList from 'ln/linkedlist/LinkedList';
import template from 'ln/template/TemplateManager';

// import project
import Element from './Element';
import LernBuch from '../LernBuch';
import GalleryModel from '../models/GalleryModel';
import GalleryImageModel from '../models/GalleryImageModel' 

/**
 * An element that renders a gallery
 */
class Gallery extends Element {

    public model:GalleryModel;
    private currentImage:LinkedList;

    constructor( model:GalleryModel ) {
        super( model );
        this.currentImage = LinkedList.fromArray( model.images );
        this.defaultTemplate = this.model.get( 'template', 'lb.gallery-element' );
    }

	/**
	 * gallery specific rendering
	 */
    public init() {
		super.init();

        // register click listener on the buttons
        this.node.js( 'next' ).click.add( this.goToNextImage, this );
        this.node.js( 'prev' ).click.add( this.goToPrevImage, this );

        this.node.mouseover.add( this.showNavigation, this );
        this.node.mouseout.add( this.hideNavigation, this );

        // render elements
        this.renderImageNodes();
        
        // show the first image
        this.showImage();
    }

    /**
     * render the image nodes
     */
    private renderImageNodes() {
		
		var images = this.model.images;
		var imageContainer = this.node.js( 'images' );

        // loop over the images. append them to the image container
        for (var i = 0; i < images.length; i++) {

	        // image-node erstellen
	        var node = Node.fromHTML( template.render( 'lb.gallery-image-element', images[i] ) );
	        node.addClass( "gallery-image", "-hidden" );
	
	        imageContainer.append(node);
        }
    }

    /**
     * function to go to the next image within the gallery
     */
    private goToNextImage() {
        this.currentImage = this.currentImage.next || this.currentImage.getFirst();
        this.showImage();
    }

    /**
     * function to go to the previous image within the gallery
     */
    private goToPrevImage() {
        this.currentImage = this.currentImage.previous || this.currentImage.getLast();
        this.showImage();
    }

    /**
     * go to the current image
     */
    private showImage() {

        if( !this.node.js( "images" ) ) {
            console.log(" js attribute \"images\" missing in template of gallery" ); 
            return;
        }

        var images = this.model.images;
        var nodes = this.node.js( "images" ).all( ".gallery-image" );
        
        nodes.forEach( ( node, index ) => {
                // toggle class on the images. hide all the images except the active one    
                node.toggleClass( '-hidden', images[index] != this.currentImage );
                // animation: fade-in the currentImage
                // node.toggleClass( 'fade-in', images[index] == this.currentImage );
        });

    }

    private showNavigation() {
        this.node.js( 'next' ).removeClass( 'hidden' );
        this.node.js( 'prev' ).removeClass( 'hidden' );
    }

    private hideNavigation() {
        this.node.js( 'next' ).addClass( 'hidden' );
        this.node.js( 'prev' ).addClass( 'hidden' );
    }

}

export default Gallery;
