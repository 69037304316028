import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Signal from 'ln/signal/Signal';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import setup from 'ln/setup/setup';
import LernBuch from '../../LernBuch';
import ChapterModel from '../../models/ChapterModel';
import TreeNode from './tree/TreeNode';
import TreeView from './tree/TreeView';
import { TreeNodeCallback } from './tree/TreeNode';
import Navigation from './Navigation';

export interface ChapterElementCallback{
	( element:ChapterElement)
}

/**
 * Renders a chapter navigation item
 */
class ChapterElement extends TreeView {


	constructor( public treeNode:TreeNode ) {
		
		super( treeNode );
		this.defaultTemplate = 'lb.navigation-chapter-element';

	}

	public init(){
		
		this.node.click.add( this.onSelected, this );

		this.viewList.container = this.node.js( 'elements' );
		this.viewList.defaultRender( function( node:TreeNode ) {
			return new ChapterElement( node ).render();
		});
		this.renderChildren( this.treeNode.children );
	}

	protected renderData():any{
		return this.treeNode.element.object();
	}

	private onSelected( n:Node, e:MouseEvent ){
		
		e.stopPropagation();

		let node = this.treeNode.root() as TreeNode;
		let chapter = node.element as ChapterModel;

		this.root.lernbuch.show( chapter, this.treeNode.element );

		/*
		var navigation = this.root as Navigation;
		navigation.toggleNavigation();
		*/

	}

}

export default ChapterElement;