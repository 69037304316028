import ElementModel from '../models/ElementModel';
import Element from './Element';
import ListRenderer from 'ln/list/ListRenderer';
import { ioc } from './Chapter';

/**
 * A class that renders other elements within its content
 */
class Section extends Element {

	data:ElementModel;
	/**
	 * Sets the configuration for an element
	 * @param model ElementModel
	 */
	constructor( model:ElementModel ) {
		super( model );
	}
	
	public init() {
		
		var elements = new ListRenderer<ElementModel>( this.node.js( 'elements' ) );
		elements.ioc = ioc;
		elements.source.fill( this.data.get<ElementModel[]>( 'content', [] ) );
	}

}

export default Section;