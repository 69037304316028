// import project
import Decorator from './Decorator';
import { DecoratorFunction } from './Decorator';
import IoC from 'ln/ioc/IoC';
import AnswerListModel from '../slides/AnswerList/AnswerListModel';
import AnswerListView from '../slides/AnswerList/AnswerList';
import SlideView from '../slides/Slide/Slide';
import SlideModel from '../slides/Slide/SlideModel';


/**
 * A class that decorates a question by showing all the correct answers.
 */
class ShowCorrect extends Decorator {

	public ioc:IoC<DecoratorFunction> = new IoC<DecoratorFunction>();


	constructor() {
		super();
		this.ioc = new IoC<DecoratorFunction>();
		this.setup();
	}

	private setup() {
		this.ioc.add( 'default', function( model:AnswerListModel, view:AnswerListView ) {} );
		this.ioc.add( 'SingleChoice', this.answerList );
		this.ioc.alias( 'App\\SingleChoice', 'SingleChoice' );
		this.ioc.add( 'MultipleChoice', this.answerList );
		this.ioc.alias( 'App\\MultipleChoice', 'MultipleChoice' );
	}

	public decorate( slideModel:SlideModel, slideView:SlideView ) {
		var key = slideView.decoratorKey;
		this.ioc.get( slideView.decoratorKey ).call( this, slideModel, slideView );
	}

	/**
	 * Decorates each answer of the answerList slide if the answer is correct or wrong.
	 */
	answerList( model:AnswerListModel, view:AnswerListView ) {

		model.answers.forEach( ( answerModel ) => {
			var link = view.answerRenderer.linkOf( answerModel );
			var answerNode = link.node;

			if( answerModel.correct ) {
				this.decorateSolutionOn( answerNode, answerModel.correct );
			}

		});	
	}

}

export default ShowCorrect;



