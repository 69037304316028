// import project
import Node from 'ln/node/Node';
import Element from './Element';
import LernBuch from '../LernBuch';
import GalleryModel from '../models/GalleryModel';
import GalleryImageModel from '../models/GalleryImageModel' 
import ElementModel from '../models/ElementModel';
import TreeBuilder from '../navigation/chapter/tree/TreeBuilder';
import TreeNode from 'ln/tree/TreeNode';
import TemplateRenderer from 'ln/template/TemplateRenderer';
import TemplateManager from 'ln/template/TemplateManager';
import View from 'ln/view/View';
import ChapterModel from 'lb/models/ChapterModel';

interface ToCModel extends ElementModel {
	level?:number,
	modelNames?:string[],
	itemTemplate?:string,
}

/**
 * An element that renders a gallery
 */
class ToC extends Element {

	public model:ToCModel;

    constructor( model:ToCModel ) {
		super( model );
		
		this.defaultTemplate = this.model.get( 'template', 'lb.toc-element' );
    }

	/**
	 * gallery specific rendering
	 */
    public init() {
		super.init();

		var builder = new TreeBuilder();
		var tree = builder.convert( this.model.chapter.book.chapters, { level: parseInt( this.model.get<any>( 'level', 2 ) ), modelNames: this.model.get( 'modelNames', ['App\\Title'] ) } );
		this.renderLevel( tree, this.node.js( 'entries' ), 0 );
	}

	protected onToCItem( element:ElementModel|ChapterModel ) {
		if( element instanceof ChapterModel ) {
			this.lernbuch.show( element );
		} else {
			this.lernbuch.show( element.chapter, element );
		}
	}
	
	protected renderLevel( trees:TreeNode[], target:Node, level ) {

		trees.forEach( function( item ) {

			var tocitem = new ToCItem( { element: item.element, level: level }, this.model.get( 'itemTemplate', 'lb.toc-element-item' ) );
			tocitem.render();
			tocitem.registerOn( this.onToCItem.bind( this ) );
			target.append( tocitem.node );

			// recursion
			if( item.children.length > 0 ) this.renderLevel( item.children, tocitem.node.js( 'children' ), level + 1 );
		}.bind( this ));
	}
}

export default ToC;


class ToCItem extends View {

	public data:{element:ElementModel|ChapterModel, level:number };

	constructor( data:{element:ElementModel|ChapterModel, level:number }, template:string ) {
		super( data );
		this.defaultTemplate = template;
	}

	renderData() {
		return { element: this.data.element.object(), level: this.data.level };
	}

	registerOn( func ) {
		this.node.js( "click" ).click.add( () => {
			func( this.data.element );
		});
	}
}

