import Node from 'ln/node/Node'; 
import LinkedList from'ln/linkedlist/LinkedList'; 
import Template from'ln/template/TemplateManager';
import Window from 'ln/node/Window';
import ModifiableList from 'ln/list/ModifiableList';
import ListRenderer from 'ln/list/ListRenderer';
import ChapterModel from'lb/models/ChapterModel';
//import BookModel from'lb/models/BookModel';

class ChapterFilter {

    filterClass:string;
    data:{ books: [ {} ]};
    chapters: ChapterModel[];
    modifiableChapterList:ModifiableList<ChapterModel>;
    chapterList:ListRenderer<ChapterModel>;
    filterButtons: Node[];

    constructor( data ) {
        this.data = data;
        this.chapters = this.getChapters( data );
        this.modifiableChapterList = new ModifiableList<ChapterModel>([]);
        this.filterClass = "";
        this.init();
    }
    
    getChapters( data ) {
        var chapters= [];
        data.books.forEach( (book)=>{
                book.chapters.forEach( (chapter) => {
                    if (chapter.hidden != true) {
                        chapters.push(chapter);
                        chapter.book = book;
                    }
                })
        });
        return chapters;
    }


    filterChapters(chapters: ChapterModel[]) {
        if ( !this.filterClass ) return chapters;
        return chapters.filter( ( chapter )=>{
            return ( chapter.book.slug === this.filterClass );
        });
    }

    init( ){ 

	    this.chapterList = new ListRenderer<ChapterModel>( Node.js( 'chapter-overview' ) );

        this.chapterList.defaultRender( function( chapter:ChapterModel ) {
            var node = Node.fromHTML( Template.render('lb.box-chapter', chapter ));
            return { node:node };
        });

        this.modifiableChapterList.fill( this.chapters );
	    this.chapterList.source = this.modifiableChapterList;
        
        this.modifiableChapterList.modifiers.append( this.filterChapters.bind(this) );
        this.filterButtons = [];
        this.data.books.forEach( function(book) {
            if( book.hidden !== true ) {
                var node = Node.fromHTML( Template.render('lb.button-filter', book ));
                Node.one( '.filter-container' ).append( node );
                this.filterButtons.push(node);
                node.click.add(  ( filter )=> {
                    filter.toggleClass('-active');
                    window.scroll(0, Node.one('#chapter-overview').bounds().top -195 );

                    //remove active class from sibling(s) ( TODO.... das sollte einfacher gehen...)
                    Node.all('.button-filter:not(.-toggle)').forEach( ( button )=> {
                        if (button.getAttribute('data-slug') !== filter.getAttribute('data-slug')) {
                            button.removeClass('-active');
                        }
                    });
                    this.filterClass = ( filter.hasClass('-active') ) ? filter.getAttribute('data-slug'): '';
                    this.modifiableChapterList.update();
                });
            }
        }.bind(this));

        var toggleButton =  Node.fromHTML(Template.render('lb.button-filter', {'title': 'Filtern nach Stufe', 'slug': 'none'} ));
        toggleButton.addClass('-toggle');
        toggleButton.removeClass('-hidden');
        toggleButton.click.add( ()=> {
            toggleButton.toggleClass('-active');
            this.filterButtons.forEach( (button)=> {
                button.toggleClass('-hidden');
            })
        })
        Node.one( '.filter-container' ).append( toggleButton );
    }

}

export default ChapterFilter;