import BaseTreeNode from "ln/tree/TreeNode";
import ElementModel from "../../../models/ElementModel";

export interface TreeNodeCallback{
    ():TreeNode;
}

class TreeNode extends BaseTreeNode {

    private _element:ElementModel;
    private _children:TreeNode[] = [];

    constructor( el:ElementModel ){
        super();
        this.element = el;
    }

    get element():ElementModel{
        return this._element;
    }

    set element( e:ElementModel ){
        this._element = e;
    }

    public addChild( n:TreeNode ){
        this.children.push( n );
    }

    get children():TreeNode[]{
        return this._children;
    }

    public getLevel():number{
        return this._element.get<number>( 'level', undefined );
    }

    public flattenChildren():TreeNode[]{
        var nodes:TreeNode[] = [];
        if( this.children && this.children.length > 0 ){
            this.children.forEach( child => {
                nodes.push( child );
                nodes = nodes.concat( child.flattenChildren() );
            });
        }
        return nodes;
    }

    public getPath( element:ElementModel ):TreeNode[]{
        var path:TreeNode[] = [];
        if( element == this.element ){
            path.push( this );
        } else {
            this.children.some( child => {
                let tmpPath = child.getPath( element );
                if( tmpPath.length > 0 ){
                    path = path.concat( tmpPath );
                    path.unshift( this );
                    return true;
                }
                return false;
            });
        }
        return path;
    }

}

export default TreeNode;